import React, { useState, useContext } from "react"
import styled from "styled-components"
import BasketIcon from "../../../static/images/basket.inline.svg"
import { CartContext } from "../../context/CartContext"

function Widget({ onClick }) {
  const [hovered, setHovered] = useState(false)
  const cartContext = useContext(CartContext)

  return (
    <>
      <WidgetWrapper>
        <BasketButton
          onClick={() => onClick && onClick()}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          border={hovered}
        >
          {cartContext?.totalItems > 0 && (
            <QuantityBubble>{cartContext?.totalItems}</QuantityBubble>
          )}
          <BasketIcon width={32} height={32} />
        </BasketButton>
      </WidgetWrapper>
    </>
  )
}

const QuantityBubble = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #BF1212;
  color: ${props => props.theme.white};
  min-block-size: 22px;
  min-height: 22px;
  min-inline-size: 22px;
  min-width: 22px;
  padding-inline: 7px;
  border-radius: 100%;
  font-size: 12px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
`

const WidgetWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  left: 40px;
  z-index: 10000;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    left: 20px;
    bottom: 20px;
  }
`

const BasketButton = styled.button`
  position: relative;
  background: ${props => props.theme.white};
  display: flex;
  color: ${props => props.theme.black};
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.33);
  padding: 14px;
  border-radius: 1000000000px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`

export default Widget
