import React from "react"
import styled from "styled-components"

function ItemList({ items, removeItem, reduceItem, currency }) {
  if (!items) {
    return
  }

  return (
    <>
      <Wrapper>
        {items.map((item, index) => {
          const cartItem = item.node
          return (
            <Item key={`basket-item-${index}`}>
              <ItemThumbnail>
                <ItemCount>{cartItem.quantity}</ItemCount>
                <img
                  src={cartItem.merchandise.product.featuredImage.url}
                  alt={`basket-item-${index}`}
                />
              </ItemThumbnail>
              <ItemDetails>
                <ItemName>{cartItem.merchandise.product.title}</ItemName>
                <ItemDetail>{cartItem.merchandise.title}</ItemDetail>
                {cartItem.quantity > 1 ? (
                  <ItemInteract
                    onClick={() =>
                      reduceItem(cartItem.id, cartItem.quantity - 1)
                    }
                  >
                    Reduce quantity
                  </ItemInteract>
                ) : (
                  <ItemInteract onClick={() => removeItem(cartItem.id)}>
                    Remove from cart
                  </ItemInteract>
                )}
              </ItemDetails>
              <ItemPrice>
                {currency}
                {cartItem.cost.amountPerQuantity.amount}
              </ItemPrice>
            </Item>
          )
        })}
      </Wrapper>
    </>
  )
}

const ItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: pre;
  min-block-size: 64px;
  min-height: 64px;
  font-size: 14px;
  margin-left: auto;
`

const Wrapper = styled.ul`
  list-style-type: none;
  padding-inline: 0;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ItemCount = styled.div`
  position: absolute;
  top: -11px;
  right: -11px;
  background: ${props => props.theme.greyTwoElectricBoogaloo};
  color: ${props => props.theme.white};
  min-block-size: 22px;
  min-height: 22px;
  min-inline-size: 22px;
  min-width: 22px;
  padding-inline: 7px;
  border-radius: 100%;
  font-size: 12px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
`

const Item = styled.li`
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
`

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemInteract = styled.button`
  margin: 0;
  font-size: 12px;
  color: ${props => props.theme.greyTwoElectricBoogaloo};
  outline: none;
  background: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  text-align: left;
  display: block;
  transition: color 120ms ease-in;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.black};
  }
`

const ItemDetail = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${props => props.theme.greyTwoElectricBoogaloo};
`

const ItemName = styled.p`
  margin: 0;
  font-size: 14px;
`

const ItemThumbnail = styled.figure`
  margin: 0;
  width: 64px;
  height: 64px;
  display: grid;
  place-items: center;
  background: ${props => props.theme.lightGrey};
  border: 1px solid ${props => props.theme.grey};
  border-radius: 8px;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    margin: 0;
  }
`

export default ItemList
