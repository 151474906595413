import { useState, useEffect } from "react";

function useIpAddress() {
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const getIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        if (isMounted) {
          setIpAddress(data.ip);
        }
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    };

    getIpAddress();

    return () => {
      isMounted = false;
    };
  }, []);

  return ipAddress;
}

export { useIpAddress };
