import { useEffect } from 'react';

const useZendeskWidget = (key) => {
  useEffect(() => {
    // Set the configuration before loading the script
    window.zESettings = {
      webWidget: {
        chat: {
          suppress: true
        },
        contactForm: {
          suppress: false,
          fields: [
            { id: 'description', prefill: { '*': 'Please leave your message here.' } }
          ]
        },
        launcher: {
          label: {
            '*': 'Leave us a message'
          }
        },
        color: {
          theme: '#000000'
        }
      }
    };

    // Create and append the script
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      const script = document.getElementById('ze-snippet');
      if (script) {
        script.remove();
      }
    };
  }, [key]);
};

export default useZendeskWidget;
