import React, { useState } from "react"
import styled from "styled-components"
import ChatIcon from "../../../static/images/chat.inline.svg"

function Widget({ onClick }) {
  const [hovered, setHovered] = useState(false)
  return (
    <>
      <WidgetWrapper>
        <AttentionBubble opacity={hovered ? true : false}>
          Any questions? Chat with us now!
        </AttentionBubble>

        <ChatButton
          onClick={() => onClick && onClick()}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          border={hovered}
        >
          <ChatIcon width={32} height={32} />
        </ChatButton>
      </WidgetWrapper>
    </>
  )
}

const AttentionBubble = styled.div`
  opacity: ${props => (props.opacity ? "100" : "0")};
  border: 2px solid ${props => props.theme.black};
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
  transition: 200ms opacity ease-in;
  background: ${props => props.theme.white};
  padding: 4px 8px;
  border-radius: 1000000000px;
  color: ${props => props.theme.black};
`

const WidgetWrapper = styled.div`
  position: fixed;
  bottom: 10vh;
  right: 4vw;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ChatButton = styled.button`
  position: relative;
  background: ${props => props.theme.white};
  display: flex;
  color: ${props => props.theme.black};
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
  padding: 8px;
  border-radius: 1000000000px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`

export default Widget
