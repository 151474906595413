import React, { useState, useEffect } from "react"
import { ChatEngineWrapper, Socket, ChatFeed } from "react-chat-engine"
import styled from "styled-components"

function Conversation({ visible, user, chat }) {
  const [showChat, setShowChat] = useState(false)
  const PROJECT_ID = process.env.GATSBY_CE_PROJECT_ID

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setShowChat(true)
      }, 500)
    }
  })

  return (
    <ConversationWrapper visible={visible}>
      {showChat && (
        <ChatEngineWrapper>
          <Socket
            projectID={PROJECT_ID}
            userName={user.email}
            userSecret={user.email}
          />
          <ChatFeed activeChat={chat.id} />
        </ChatEngineWrapper>
      )}
    </ConversationWrapper>
  )
}

export default Conversation

const ConversationWrapper = styled.div`
  transition: all 500ms ease;
  width: 100%;
  background: ${props => props.theme.white};
  color: ${props => props.theme.lightGrey};
  z-index: ${props => (props.visible ? 10002 : 0)};
  height: ${props => (props.visible ? "100%" : 0)};
`
