import React, { useState } from "react"

import styled from "styled-components"

function Form({ setUser, setChat, visible }) {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  function handleUser(callback) {
    const privateKey = process.env.GATSBY_CE_PRIVATE_KEY
    const url = "https://api.chatengine.io/users/"

    const requestOptions = {
      method: "PUT",
      headers: {
        "Private-Key": privateKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: email, email: email, secret: email }),
    }

    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(data => callback(data))
      .catch(error => console.error("Get or create user error", error))
  }

  function handleChat(callback) {
    const projectID = process.env.GATSBY_CE_PROJECT_ID
	const url = "https://api.chatengine.io/chats/"

    const requestOptions = {
      method: "PUT",
      headers: {
        "Project-ID": projectID,
        "User-Name": email,
        "User-Secret": email,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usernames: [email, "Steel-Skins Support"],
        is_direct_chat: true,
      }),
    }

    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then(data => callback(data))
      .catch(error => console.error("Get or create chat error", error))
  }

  function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    //console.log("Sending Email", email)

    handleUser(user => {
      setUser && setUser(user)
      handleChat(chat => {
        setLoading(false)
        setChat && setChat(chat)
      })
    })
  }

  return (
    <>
      <FormWindow visible={visible ? true : false}>
        <div style={{ height: 0 }}>
          <Stripe />
        </div>
        <Loading loading={loading ? true : false} />

        <Visuals>
          <Intro>
            Welcome to Steel-Skins <br /> support chat 👋
          </Intro>
          <EmailForm onSubmit={e => handleSubmit(e)}>
            <Field
              placeholder="Your Email"
              onChange={e => setEmail(e.target.value)}
            />
          </EmailForm>
          <Outro>
            Enter your email
            <br />
            to get started.
          </Outro>
        </Visuals>
      </FormWindow>
    </>
  )
}

export default Form

const FormWindow = styled.div`
  width: 100%;
  height: ${props => (props.visible ? "100%" : "0")};
  opacity: ${props => (props.visible ? "1" : "0")};
  transition: all 500ms ease;
  overflow: hidden;
  background: ${props => props.theme.white};
`
const Stripe = styled.div`
  height: 0px;
  position: relative;
  top: -45px;
  width: 100%;
  height: 308px;
  background: ${props => props.theme.black};
  transform: skewY(-12deg);
`

const Loading = styled.div`
  opacity: ${props => (props.loading ? "0.33" : "0")};
  z-index: ${props => (props.loading ? "10001" : "-1")};
  position: absolute;
  height: 100%;
  width: 100%;
  align-text: center;
  background: ${props => props.theme.white};
  transition: opacity 500ms ease;
`

const Visuals = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
`

const Intro = styled.p`
  position: relative;
  width: 100%;
  top: 22.5%;
  color: ${props => props.theme.white};
  font-size: 24px;
  font-weight: 600;
  margin-block-start: 0;
  margin-block-end: 0;
`
const EmailForm = styled.form`
  position: relative;
  width: 100%;
  top: 32%;
`

const Field = styled.input`
  width: 66%;
  align-text: center;
  outline: none;
  padding: 12px;
  border-radius: 12px;
  border: 2px solid ${props => props.theme.black};
`

const Outro = styled.p`
  position: relative;
  width: 100%;
  top: 45%;
  color: ${props => props.theme.black};
  font-size: 24px;
  font-weight: 600;
  margin-block-start: 0;
  margin-block-end: 0;
  align-text: center;
`
