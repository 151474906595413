import React, { useCallback, useContext, useState, useEffect } from "react"
import styled from "styled-components"
import ItemList from "./ItemList"
import { CartContext } from "../../context/CartContext"
import currencies from "../../hooks/currencies.json"

function Window({ visible }) {
  const [totalPrice, setTotalPrice] = useState(0)
  const [country, setCountry] = useState("")
  const cartContext = useContext(CartContext)
  const items = cartContext?.cartItems?.lines?.edges

  const calculateTotalPrice = () => {
    let total = 0

    for (let index = 0; index < items?.length; index++) {
      let newPrice = parseFloat(items[index].node.cost.totalAmount.amount)
      total += newPrice
    }

    setTotalPrice(total)
  }

  const setCountryCode = () => {
    setCountry(cartContext?.cartItems?.cost?.totalAmount?.currencyCode)
  }

  useEffect(() => {
    calculateTotalPrice()
    setCountryCode()
  }, [items])

  const cartQuery = `cart {
    id
    createdAt
    updatedAt
    checkoutUrl
    cost {
      totalAmount {
        amount
        currencyCode
      }
    }
    totalQuantity
    lines(first: 10) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              title
              product {
                title
                featuredImage {
                  url
                }
              }
            }
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
  `

  const removeItemFromCart = useCallback(id => {
    fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": "b264d4580ba51d9e653598195d2fb32a",
      },
      body: `mutation @inContext(country: ${cartContext?.country}) {
        cartLinesRemove(cartId: "${cartContext?.cartId}", lineIds: "${id}") {
          ${cartQuery}
        }
      }`,
    })
      .then(response => response.json())
      .then(result => {
        cartContext?.setCartItems(result?.data?.cartLinesRemove?.cart)
        cartContext?.setTotalCost(
          result?.data?.cartLinesRemove?.cart?.cost?.totalAmount?.amount
        )
        cartContext?.setTotalItems(
          parseInt(result?.data?.cartLinesRemove?.cart?.totalQuantity)
        )
      })
      .catch(error => {
        console.error("Error:", error)
        alert("Error removing item, please try again. [E04]")
      })
  })

  const reduceItemFromCart = useCallback((id, quantity) => {
    fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": "b264d4580ba51d9e653598195d2fb32a",
      },
      body: `mutation @inContext(country: ${cartContext?.country}) {
        cartLinesUpdate(cartId: "${cartContext?.cartId}", lines: { 
          id: "${id}"
          quantity: ${quantity}
        }) {
          ${cartQuery}
        }
      }`,
    })
      .then(response => response.json())
      .then(result => {
        cartContext?.setCartItems(result?.data?.cartLinesUpdate?.cart)
        cartContext?.setTotalCost(
          result?.data?.cartLinesUpdate?.cart?.cost?.totalAmount?.amount
        )
        cartContext?.setTotalItems(
          parseInt(result?.data?.cartLinesUpdate?.cart?.totalQuantity)
        )
      })
      .catch(error => {
        console.error("Error:", error)
        alert("Error updating item, please try again. [E05]")
      })
  })

  return (
    <>
      <WindowWrapper visible={visible}>
        <Title>Your cart</Title>
        <ItemList
          removeItem={removeItemFromCart}
          reduceItem={reduceItemFromCart}
          currency={currencies[country]?.symbol}
          items={items}
        />
        <TotalPrice>
          <span>Total:</span>
          <span className="price-tag">
            <span className="currency">{country}</span>
            {currencies[country]?.symbol}
            {totalPrice.toFixed(2)}
          </span>
        </TotalPrice>
        <CheckoutButton
          disabled={items <= 0}
          onClick={() => (window.location = cartContext?.cartUrl)}
        >
          Go to checkout
        </CheckoutButton>
      </WindowWrapper>
    </>
  )
}

const TotalPrice = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  font-weight: 500;

  .price-tag {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
  }

  .currency {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    color: ${props => props.theme.greyTwoElectricBoogaloo};
  }
`

const CheckoutButton = styled.button`
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.black};
  outline: none;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border-radius: 28px;
  padding: 6px 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: ${props => props.theme.white};
    color: ${props => props.theme.black};
  }
`

const WindowWrapper = styled.div`
  background: ${props => props.theme.white};
  position: fixed;
  bottom: 180px;
  border-radius: 12px;
  left: 40px;
  z-index: 10000;
  max-width: min(calc(100% - 40px), 420px);
  max-height: min(calc(100% - 40px), 530px);
  border: 2px solid ${props => props.theme.white};
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
  opacity: ${props => (props.visible ? 100 : 0)};
  pointer-events: ${props => (props.visible ? "all" : "none")};
  overflow: hidden;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    left: 20px;
    bottom: 100px;
  }
`

const Title = styled.p`
  color: ${props => props.theme.black};
  font-weight: 500;
  text-align: left;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: 0.3px;
  margin-block: 0;
`

export default Window
