import { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";

const app = initializeApp({
  apiKey: "AIzaSyCyACDpsXArox8d8_pv8Sct5QrCG5IPoBw",
  authDomain: "steel-skins.firebaseapp.com",
  projectId: "steel-skins",
  storageBucket: "steel-skins.appspot.com",
  messagingSenderId: "785808948143",
  appId: "1:785808948143:web:8fe7d8479121b07d850d5a",
});

const db = getFirestore(app);
const trackersCollectionRef = collection(db, "tracked_ips");

async function getCampaignFromStore(ipAddress) {
  if (typeof window === "undefined") {
    return null;
  }

  const queryParameters = new URLSearchParams(window.location.search);
  const campaignIdFromUrl = queryParameters.get("ref_camp");
  const cachedCampaignId = localStorage.getItem("campaign_id");

  // If we have a campaign ID from the URL or localStorage, use it without querying Firebase
  const campaign_id = campaignIdFromUrl || cachedCampaignId;

  if (campaign_id) {
    localStorage.setItem("campaign_id", campaign_id);
    return campaign_id;
  }

  console.log("ipAddress: ", ipAddress)

  // If no campaign ID found, then query Firebase for the IP address (incase client has used a different browser)
  const matchingTrackerQuery = query(
    trackersCollectionRef,
    where("ip_address", "==", ipAddress)
  );
  const snapshot = await getDocs(matchingTrackerQuery);

  if (!snapshot.empty) {
    const trackersData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const matchingTracker = trackersData.find(
      (tracker) => tracker.campaign_id !== undefined
    );

    if (matchingTracker) {
      localStorage.setItem("campaign_id", matchingTracker.campaign_id);
      return matchingTracker.campaign_id;
    }
  }

  return null;
}

const useCampaignTracker = (ipAddressToCheck) => {
  const [campaignData, setCampaignData] = useState(null);
  const initializedRef = useRef(false);

  console.log("initializedRef.current:", initializedRef.current)
  console.log("ipAddressToCheck: ", ipAddressToCheck)

  useEffect(() => {
    const fetchData = async () => {
      if (!ipAddressToCheck || initializedRef.current) return;

      // Check if data is already cached in localStorage
      const cachedData = JSON.parse(localStorage.getItem(ipAddressToCheck));
      if (cachedData) {
        setCampaignData(cachedData);
        return;
      }

      // Fetch campaign identifier
      const campaignIdentifier = await getCampaignFromStore(ipAddressToCheck);

      if (!campaignIdentifier) return;

      const matchingTrackerQuery = query(
        trackersCollectionRef,
        where("ip_address", "==", ipAddressToCheck)
      );
      const snapshot = await getDocs(matchingTrackerQuery);

      if (!snapshot.empty) {
        // If an entry exists, use the existing data
        const trackersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const matchingTracker = trackersData.find((tracker) => true);

        if (matchingTracker) {
          const existingData = {
            id: matchingTracker.campaign_id,
            date: matchingTracker?.tracking_date?.toDate().toUTCString() ?? new Date().toUTCString(),
          };
          setCampaignData(existingData);
          localStorage.setItem(ipAddressToCheck, JSON.stringify(existingData));
          initializedRef.current = true;
          return;
        }
      }

      // If no entry exists, create a new one
      const newTrackerData = {
        ip_address: ipAddressToCheck,
        campaign_id: campaignIdentifier,
        tracking_date: Timestamp.now(),
      };
      await addDoc(trackersCollectionRef, newTrackerData);

      setCampaignData({
        id: campaignIdentifier,
        date: newTrackerData.tracking_date.toDate().toUTCString(),
      });
      localStorage.setItem(ipAddressToCheck, JSON.stringify(newTrackerData));
      initializedRef.current = true;
    };

    fetchData();
  }, [ipAddressToCheck]);

  return campaignData;
};

export { useCampaignTracker };
