import React, { useState, useEffect } from "react"
import styled from "styled-components"

function BannerNotice() {
  const [notice, setNotice] = useState(null)

  useEffect(() => {
    function getNotice() {
      fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/graphql",
          "X-Shopify-Storefront-Access-Token":
            "b264d4580ba51d9e653598195d2fb32a",
        },
        body: `{
            metaobject(handle: {handle: "navigation-preorder-notice", type: "pre_order_notice"}) {
              field(key: "text") {
                value
              }
            }
          }`,
      })
        .then(request => request.json())
        .then(response => setNotice(response?.data?.metaobject?.field?.value))
    }
    getNotice()
  }, [])

  if (!notice) {
    return
  }


  return (
    <>
      <BannerMessage>
        <Message>
          <span>Important Information!</span>
          <span>
            {notice}
          </span>
        </Message>
      </BannerMessage>
    </>
  )
}

const BannerMessage = styled.div`
  background-color: #fff;
  width: 100vw;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const Message = styled.p`
  max-width: 1140px;
  padding: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-block: 0;

  span {
    &:first-child {
      font-size: 14px;
      font-weight: 600;
    }
  }
`

export default BannerNotice
