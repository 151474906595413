import React, { createContext, useContext, useMemo } from "react";
import { useIpAddress } from "../hooks/useIpAddress";
import { useCampaignTracker } from "../hooks/useCampaignTracker";

export const CampaignContext = createContext(null);

export default function CampaignContextProvider({ children }) {
  const ipAddress = useIpAddress();
  const campaignId = useCampaignTracker(ipAddress);

  const value = useMemo(() => ({ campaignId }), [campaignId]);

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
}

export function useCampaignContext() {
  const context = useContext(CampaignContext);

  if (!context) {
    throw new Error(
      "useCampaignContext must be used within a CampaignContextProvider"
    );
  }

  return context;
}
