import React, { useState } from "react"
import styled from "styled-components"
import Form from "./Form"
import Conversation from "./Conversation"

function Window({ visible }) {
  const [user, setUser] = useState(null)
  const [chat, setChat] = useState(null)

  return (
    <>
      <WindowWrapper visible={visible}>
        {user === null && chat === null ? (
          <Form
            visible={user === null || chat === null}
            setUser={user => setUser(user)}
            setChat={chat => setChat(chat)}
          />
        ) : (
          <Conversation
            visible={user !== null && chat !== null}
            user={user}
            chat={chat}
          />
        )}
      </WindowWrapper>
    </>
  )
}

const WindowWrapper = styled.div`
  background: ${props => props.theme.black};
  position: fixed;
  bottom: 16vh;
  border-radius: 12px;
  right: 4vw;
  z-index: 10000;
  width: 420px;
  max-width: calc(100% - 48px);
  height: 530px;
  max-height: calc(100% - 48px);
  border: 2px solid ${props => props.theme.black};
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
  opacity: ${props => (props.visible ? 100 : 0)};
  overflow: hidden;
`

export default Window
