import { useEffect } from 'react';

export default function useFacebookPixel() {
    useEffect(() => {
        (function(f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        window.fbq('init', '639978341667753'); //1230106145030729
        //if (process.env.NODE_ENV === 'development') {
            //window.fbq('set', 'test_event_code', 'TEST40698'); 
        //}
        window.fbq('track', 'PageView');
    }, []);
}

export function trackAddToCart(productDetails) {
    if (typeof window.fbq === 'function') {
        window.fbq('track', 'AddToCart', {
            content_name: productDetails.name,
            value: productDetails.price,
            currency: 'GBP'
        });

        console.log('pixel sent');
    } else {
        console.log('pixel not yet initialised but add to cart track requested');
    }
}

export function getFbcCookie() {
    const cookies = document.cookie.split(';');
    const fbcCookie = cookies.find(cookie => cookie.trim().startsWith('_fbc='));
    if (fbcCookie) {
        return fbcCookie.split('=')[1];
    }
    return null;
}

export function getFbpCookie() {
    const cookies = document.cookie.split(';');
    const fbpCookie = cookies.find(cookie => cookie.trim().startsWith('_fbp='));
    if (fbpCookie) {
        return fbpCookie.split('=')[1];
    }
    return null;
}