import React, { useEffect, useState } from "react";
import { CartContext } from "../../context/CartContext";
import { useIsomorphicLayoutEffect } from "../../hooks/useServerHooks";

function Cart({ children }) {
  const [cartUrl, setCartUrl] = useState(null);
  const [cartId, setCartId] = useState(
    typeof window !== "undefined" ? localStorage.getItem("cart_id") || "" : ""
  );
  const [country, setCountry] = useState(null);
  const [totalCost, setTotalCost] = useState(0.0);
  const [totalItems, setTotalItems] = useState(0);
  const [cartItems, setCartItems] = useState({});

  useIsomorphicLayoutEffect(() => {
    function retrieveCountry() {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((result) => {
          setCountry(result?.country_code);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Error getting country, please try again. [E02]");
        });
    }

    retrieveCountry();
  }, []);

  useEffect(() => {
    if (country && cartId) {
      updateCartBuyerIdentity(country);
    }
  }, [country, cartId]);

function updateCartBuyerIdentity(countryCode) {
    console.log("updating cart identity");
    fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": "b264d4580ba51d9e653598195d2fb32a",
      },
      body: `mutation {
        cartBuyerIdentityUpdate(
          cartId: "${cartId}"
          buyerIdentity: { countryCode: ${countryCode} }
        ) {
          cart {
            id
            buyerIdentity {
              countryCode
            }
          }
        }
      }`,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.data?.cartBuyerIdentityUpdate?.cart) {
          console.log("Buyer identity updated:", result.data.cartBuyerIdentityUpdate.cart);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error updating currency, please try again. [E04]");
      });
  }

  function updateCartId(newCartId) {
    if (typeof window === "undefined") {
      return;
    }

    setCartId(newCartId);
    localStorage.setItem("cart_id", newCartId);
  }

  function createCart() {
    fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": "b264d4580ba51d9e653598195d2fb32a",
      },
      body: `mutation @inContext(country: ${country}) {
          cartCreate {
            cart {
              id
              createdAt
              updatedAt
              checkoutUrl
              totalQuantity
              note
              cost {
                  totalAmount {
                    amount
                  }
                }
            }
          }
        }`,
    })
      .then((response) => response.json())
      .then((result) => {
        setCartUrl(result?.data?.cartCreate?.cart?.checkoutUrl);
        const newCartId = result?.data?.cartCreate?.cart?.id;
        if (newCartId) {
          updateCartId(newCartId);
        }
        setTotalCost(result?.data?.cartCreate?.cart?.cost?.totalAmount?.amount);
        setTotalItems(parseInt(result?.data?.cartCreate?.cart?.totalQuantity));
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error generating checkout, please try again. [E01]");
      });
  }

  function retrieveCart() {
    fetch("https://steel-skins.myshopify.com/api/2023-07/graphql.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": "b264d4580ba51d9e653598195d2fb32a",
      },
      body: `query @inContext(country: ${country}) {
          cart(id: "${cartId}") {
            id
            createdAt
            updatedAt
            checkoutUrl
            note
            cost {
              totalAmount {
                amount
                currencyCode
              }
            }
            totalQuantity
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      title
                      product {
                        title
                        featuredImage {
                          url
                        }
                      }
                    }
                  }
                  cost {
                    amountPerQuantity {
                      amount
                      currencyCode
                    }
                    totalAmount {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }`,
    })
      .then((response) => response.json())
      .then((result) => {
        setCartItems(result?.data?.cart);
        setCartUrl(result?.data?.cart?.checkoutUrl);
        setTotalCost(result?.data?.cart?.cost?.totalAmount?.amount);
        setTotalItems(parseInt(result?.data?.cart?.totalQuantity));
        if (!cartItems) {
          createCart();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error retrieving checkout, please try again. [E03]");
      });
  }

  useEffect(() => {
    if (!cartId) {
      createCart();
    } else {
      retrieveCart();
    }
  }, [cartId]);

  return (
    <>
      <CartContext.Provider
        value={{
          cartUrl,
          cartId,
          country,
          totalCost,
          totalItems,
          setTotalItems,
          cartItems,
          setCartItems,
          retrieveCart,
          country,
          setTotalCost,
        }}
      >
        {children}
      </CartContext.Provider>
    </>
  );
}

export default Cart;
