export const lightTheme = {
  // Colours
  // background: '#18151f',
  // middleground: '#272731', // 2e2e38
  // foreground: '#1f1d29',

  // primary: '#692de0',
  // secondary: '',
  // tertiary: '',

  // red: '#cc2936',
  green: '#4d8b31',
  // yellow: '#eac435',
  // white: '#fff',

  // Layout
  // margin: '16px',
  // padding: '16px',
  // halfMargin: '8px',
  // halfPadding: '8px',
  // qtrMargin: '4px',
  // qtrPadding: '4px',
  // radius: '5px',
  // shadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
  // standardHeight: '36px',

  // Colours
  footerBackground: '#07112D',
  footerColour: '#6a7c92',
  // pageBackground: '#f5f8ff',

  // Layout
  radius: '15px',

  // Fonts
  header: '600 50px Poppins',
  question: '600 24px Poppins', 
  paragraph: '500 18px Poppins', 
  bold: '600 14px Poppins',
  normal: '500 14px Poppins',
  light: '500 12px Poppins',

  // Font colours
  linkColour: '#fff',
  darkLinkColour: '#051441',
  white: '#fff',
  grey: '#ccc',
  greyTwoElectricBoogaloo: '#555',
  lightGrey: 'rgba(237, 237, 237, 1)',
  darkParagraph: '#6a7c92',
  black: '#000',

  // Gradients
  gradient1: 'linear-gradient(180deg, rgba(237, 237, 237, 1), rgba(237, 237, 237, 1) 100%)'
};
