import React, { useState, useEffect, useRef } from "react"
import Widget from "./Widget"
import Window from "./Window"

function Basket() {
  const wrapperRef = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("touchstart", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <>
      <div ref={wrapperRef}>
        <Window visible={visible} />
        <Widget onClick={() => setVisible(!visible)} />
      </div>
    </>
  )
}

export default Basket
