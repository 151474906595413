import { createGlobalStyle } from "styled-components"

// typeof createGlobalStyle === 'function' &&
export default typeof createGlobalStyle === "function"
  ? createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.fontSlider {
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
}
.sliderTrack {
  background-color: grey;
  height: 2px;
}
.sliderThumb {
  border-radius: 50%;
  background-color: #523cdc;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all ease .1s;
}

.tippy-tooltip-content {
  font: 400 12px Poppins;
}
`
  : null
